import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';

const style = {
  chip: {
    marginRight: 7,
    marginBottom: 7,
    width: 70,
    height: 70,
  },
  avatar: {
    width: 70,
    height: 70,
  }
};

export default class Attachment extends React.Component {
  handleRequestDelete = () => {
    if (!this.props.disabled) {
      this.props.onRemove();
    }
  }

  render() {
    return (
      <Chip
        avatar={<Avatar alt={this.props.image.name} src={this.props.image.data} className={style.avatar} />}
        label={this.props.image.name}
        onDelete={this.handleRequestDelete}
        className={style.chip}
      />
    );
  }
}