import actionTypes from './ActionTypes';
import * as personUtils from '../utils/PersonUtils';
import * as userActions from './UserActions';

const getPersonListSuccess = (res) => ({
  type: actionTypes.GET_PERSON_LIST_SUCCESS,
  res
});

const getPersonListRequest = () => ({
  type: actionTypes.GET_PERSON_LIST_REQUEST
});

const getPersonListFailure = (error) => ({
  type: actionTypes.GET_PERSON_LIST_FAILURE,
  error
});

export const getPersonList = (text, countCharStart) => {
  return async (dispatch) => {
    try {
      dispatch(getPersonListRequest());
      let res = await personUtils.getPersonList(text, countCharStart);
      dispatch(getPersonListSuccess(res));
    } catch (error) {
      dispatch(userActions.showMsg('Neočakávaná chyba.'));
      dispatch(getPersonListFailure(error))
      console.error(error);
    }
  }
}

const getPersonSuccess = (res) => ({
  type: actionTypes.GET_PERSON_SUCCESS,
  res
});

const getPersonRequest = () => ({
  type: actionTypes.GET_PERSON_REQUEST
});

const getPersonFailure = (error) => ({
  type: actionTypes.GET_PERSON_FAILURE,
  error
});

export const getPerson = (id) => {
  return async (dispatch) => {
    try {
      dispatch(getPersonRequest());
      let res = await personUtils.getPerson(id);
      dispatch(getPersonSuccess(res));
    } catch (error) {
      dispatch(userActions.showMsg('Neočakávaná chyba.'));
      dispatch(getPersonFailure(error))
      console.error(error);
    }
  }
}