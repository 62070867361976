import React from 'react';
import Paper from '../../coraWebMComponents/surfaces/Paper';
import AppBar from '../appBar/AppBar';
import Info from '../../coraWebMComponents/feedBack/Info';
import CircularProgress from '../../coraWebMComponents/feedBack/progress/CircularProgress';
import { connect } from 'react-redux';
import routes from '../../routes';
import * as convert from '../../coraWebMComponents/utils/convert';
import * as evoActions from '../../actions/EvoActions';
import withRouter from '../../coraWebMComponents/utils/withRouter';

const _initState = {
  loading: false,
}
class Evo extends React.Component {
  constructor(props) {
    super(props);
    this.state = _initState;
  }
    render() {
    return (
      <div>
        <AppBar title='Lustrácia vozidiel v evidencii PZ MVSR' closable />
        <CircularProgress loading={this.state.loading} />
        <div className='container'>
          <Paper className='content' elevation={1} >
          <React.Fragment>
            {this.props.isSuccess ?
              <Item 
                data={this.props.evo} 
                user={this.props.user} 
                city={this.props.cityOfSetup ? this.props.cityOfSetup.HODN : ''}  
                adress={this.props.adressOfSetup ? this.props.adressOfSetup.HODN : ''} /> :
                this.state.loading ? <Info text="" />:<Info text={this.props.msg} />
            }
            </React.Fragment>
          </Paper>
         </div>
      </div>
    );
  }

  componentDidMount = async () => {
    this.setState({ loading: true });
    await this.props.dispatch(evoActions.getCityOfSetup());
    await this.props.dispatch(evoActions.getAdressOfSetup());

    const form = this.props.location.state;
    if (form) {      
      await this.props.dispatch(evoActions.getEvo(form));
    }
    else {
      this.props.navigate(routes.EVO_SEARCH);
    }
    this.setState({ loading: false });
  }
}

class Item extends React.Component {
  format = val => {
    return val !== null ? val : '';
  }

  formatDrzitel = (drzitel) => {
    if (!drzitel || !drzitel.POBYT) {
      return ''
    }

    return `${this.format(drzitel.RODNE_CISLO)} / ${this.format(drzitel.ICO)}, 
      ${this.format(drzitel.MENO)} ${this.format(drzitel.PRIEZVISKO)} / ${this.format(drzitel.NAZOV)}, 
      ${this.format(drzitel.POBYT.ULICA)} ${this.format(drzitel.POBYT.SUPISNE_CISLO)}/${this.format(drzitel.POBYT.ORIENTACNE_CISLO)}, ${this.format(drzitel.POBYT.OBEC)}`;
  }

  render() {
    return (
      <div>
        <div className="item">
          <div><b>VIN:</b> {this.props.data.VIN}</div>
          <div><b>EČ vozidla:</b> {this.props.data.EVIDENCNE_CISLO}</div>
          <div><b>Držiteľ:</b> {this.formatDrzitel(this.props.data.DRZITEL)}</div>
          <div><b>Budúci držiteľ:</b> {this.formatDrzitel(this.props.data.BUDUCI_DRZITEL)}</div>
          <div><b>Dátum zmeny:</b> {convert.convertDate(this.props.data.DATUM_ZMENY)}</div>
          <div><b>Kategória:</b> {this.props.data.KATEGORIA_VOZIDLA}</div>
          <div><b>Druh:</b> {this.props.data.DRUH_VOZIDLA}</div>
          <div><b>Značka:</b> {this.props.data.ZNACKA}</div>
          <div><b>Farba:</b> {this.props.data.FARBA}</div>
          <div><b>Stav:</b> {this.props.data.STAV_VOZIDLA}</div>
          <div><b>Prihlásený používateľ:</b> {this.props.user}</div>
          <div><b>Dátum a čas:</b> {new Date().toLocaleDateString()} {new Date().toLocaleTimeString()}</div>
          <div><br></br></div>
          <div>Vygenerované z mobilnej aplikácie Mestskej polície - CG MAMP</div>
          <div>Mesto {this.props.city}, {this.props.adress}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    evo: state.evo.data,
    isSuccess: state.evo.status && state.evo.status.CODE === 'eGOV100',
    msg: state.evo.status ? state.evo.status.TEXT : 'Nenašiel sa záznam o vozidle.',
    isLoading: state.evo.isLoading,
    user: state.user.data.UserFullName,
    cityOfSetup: state.cityOfSetup.data,
    adressOfSetup: state.adressOfSetup.data,
  }
}

export default withRouter(connect(mapStateToProps)(Evo));