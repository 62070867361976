import React from 'react';
import List from '../../coraWebMComponents/dataDisplay/List';
import ListItem from '../../coraWebMComponents/dataDisplay/ListItem';
import Paper from '../../coraWebMComponents/surfaces/Paper';
import AppBar from '../appBar/AppBar';
import Info from '../../coraWebMComponents/feedBack/Info';
import LinearProgress from '../../coraWebMComponents/feedBack/progress/LinearProgress';
import TelContainer from '../../coraWebMComponents/dataDisplay/TelContainer';

import { connect } from 'react-redux';
import routes from '../../routes';
import * as convert from '../../coraWebMComponents/utils/convert';
import * as dogActions from '../../actions/DogActions';
import withRouter from '../../coraWebMComponents/utils/withRouter';

class Dog extends React.Component {

  render() {
    return (
      <div>
        <AppBar title='Pes' closable />
        <LinearProgress loading={this.props.isLoading} />
        <div className='container'>
          <Paper className='content' elevation={1} >
             <React.Fragment>
            {(this.props.dogs.length === 0 && !this.props.isLoading) &&
              <Info text='Nenájdený žiaden pes.' />
            }
            <List>
              {this.props.dogs.map((dog) =>
                <Item key={dog.C_ZNAMKA} data={dog} />
              )}
            </List>
             </React.Fragment>
          </Paper>
        </div>
      </div>
    );
  }

  componentDidMount() {
    const form = this.props.location.state;
    if (form) {
      this.props.dispatch(dogActions.getDogs(form));
    }
    else {
      this.props.navigate(routes.DOG_SEARCH);
    }
  }
}

class Item extends React.Component {
  render() {
    let tel = this.props.data.KONTAKT ? this.props.data.KONTAKT.split(', ') : [];

    return (
      <ListItem>
        <div className="item">
          <div><b>Majiteľ:</b> {this.props.data.MAJITEL}</div>
          <div><b>Adresa TP majiteľa:</b> {this.props.data.ADRESA_TP}</div>
          <div><b>Adresa držania psa:</b> {this.props.data.ADRESA_PSA}</div>
          <div><b>Meno psa:</b> {this.props.data.N_PES}</div>
          <div><b>Číslo známky:</b> {this.props.data.C_ZNAMKA}</div>
          <div><b>Čipové číslo:</b> {this.props.data.C_CIP}</div>
          <div><b>Rasa:</b> {this.props.data.N_C_PLEM}</div>
          <div><b>Nebezpečný:</b> {this.props.data.NEBEZPECNY}</div>
          <div><b>Vyradený z evidencie:</b> {this.props.data.L_VYR ? 'Áno' : 'Nie'}</div>
          <div><b>Dátum zrušenia:</b> {convert.convertDate(this.props.data.D_ZRUS)}</div>
          <div><b>Dôvod vyradenia:</b> {this.props.data.N_C_VYR_PES}</div>
          <div><b>Kontakt:</b> <TelContainer data={tel} /></div>
        </div>
      </ListItem>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dogs: state.dogs.data,
    isLoading: state.dogs.isLoading,
  }
}

export default withRouter(connect(mapStateToProps)(Dog));