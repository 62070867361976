import React from 'react';
import DogIcon from '@material-ui/icons/Pets';

class DogIconComponent extends React.Component {
  render() {
    return (
      <DogIcon />
    )
  }
}

export default DogIconComponent;