
import actionTypes from './ActionTypes';
import * as userUtils from '../utils/UserUtils';

const loginSuccess = (user) => ({
  type: actionTypes.LOGIN_SUCCESS,
  user
});

const loginRequest = () => ({
  type: actionTypes.LOGIN_REQUEST
});

const loginFailure = (error) => ({
  type: actionTypes.LOGIN_FAILURE,
  error
});

export const login = (username, pwd) => {
  return async (dispatch) => {
    try {
      dispatch(loginRequest());
      let user = await userUtils.login(username, pwd);
      dispatch(loginSuccess(user));
    } catch (error) {
      dispatch(loginFailure(error))
      console.error(error);
    }
  }
}

export const showMsg = (msg) => ({
  type: actionTypes.SHOW_MSG,
  msg
});

export const hideMsg = () => ({
  type: actionTypes.HIDE_MSG
});


export const showErrorMsg = (errorMsg) => ({
  type: actionTypes.SHOW_ERROR_MSG,
  errorMsg
});

export const hideErrorMsg = () => ({
  type: actionTypes.HIDE_ERROR_MSG
});

export const logout = () => ({
  type: actionTypes.LOGOUT_SUCCESS
});