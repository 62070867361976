import _ from 'lodash';
import { strToDate, addOffset } from './convert';
import * as storage from './storage';

/** @module utils/helper */

const dateTypes = [
  "date",
  "time",
  "datetime",
  "dateinput",
  "timeinput",
  "datetimeinput",
  "4",
  "8",
  "13",
  "18",
  "19",
  "20"
];

export const isDateType = (type) => {
  return dateTypes.includes(type);
}


/**
 * Checks if input string is null or empty.
 * e.g., '' -> true
 * @function stringIsNullOrEmpty
 * @param {string} string string input
 * @returns {boolean}
 */
export const stringIsNullOrEmpty = (string) => {
  return (!string || string.length === 0 || !string.trim() || /^\s*$/.test(string));
}

/**
 * Returns url as string which is corrected by set theme.
 * @function buildUrl
 * @param {string} name url
 * @returns {(string|void)}
 */
export const buildUrl = (name) => {
  const theme = document.body.className;
  if (name) {
    switch (theme) {
      case 'theme-dark':
        name = name.replace(".svg", "-dark.svg");
        break;
      case 'theme-pink':
        name = name.replace(".svg", "-pink.svg");
        break;
      case 'theme-underwater':
        name = name.replace(".svg", "-underwater.svg");
        break;
      case 'theme-army':
        name = name.replace(".svg", "-army.svg");
        break;
      default:
        return name;
    }
    return name;
  }
}

/**
 * Returns Google OAuth url
 * @function buildGoogleOauthUrl
 * @param {string} redirectURI - Redirect url
 * @param {string} state - State
 * @returns {string}
 */
export const buildGoogleOauthUrl = (redirectURI, state) => {
  return `https://accounts.google.com/o/oauth2/v2/auth?scope=https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email&response_type=code&redirect_uri=${redirectURI}&client_id=${window.config.google.id}${state !== undefined ? `&state=${state}` : ''}`;
}

/**
 * Returns version of client as string.
 * @function getClientVersion
 * @returns {string}
 */
export const getClientVersion = () => {
  return process.env.REACT_APP_VERSION.substring(0, 4) + '.' + process.env.REACT_APP_VERSION.substring(4);
}

/**
 * Executes every function in child schema elements.
 * @function eachDeep
 * @param {Array} columns array schema object
 * @param {Object} parent parent object
 * @param {Function} fn function to be executed
 */
export var eachDeep = (columns, parent, fn) => {
  if (!_.isArray(columns)) return;
  columns.forEach(item => {
    eachDeep(item.schema, item, fn);
    fn(item, parent);
  });
}

/**
 * Copy string to clipboard.
 * @function copyToClipboard
 * @param {string} data string input
 */
export var copyToClipboard = (data) => {
  var actEl = document.activeElement;
  var el = document.createElement("textarea");
  el.value = data;
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
  actEl.focus();
}

/**
 * Replaces diacritical mark letters in input string with letters without diacritical mark.
 * @function removeAccents
 * @param {string} str string input
 * @returns {string}
 */
export var removeAccents = (str) => {
  var accents = 'ÁÄáäÓÔóôÉéČčĎďÍíÚúŇňŠšÝýŽžĹĽĺľŕŤť';
  var accentsOut = "AAaaOOooEeCcDdIiUuNnSsYyZzLLllrTt";
  str = str.split('');
  var strLen = str.length;
  var i, x;
  for (i = 0; i < strLen; i++) {
    if ((x = accents.indexOf(str[i])) !== -1) {
      str[i] = accentsOut[x];
    }
  }
  return str.join('');
}

/**
 * Returns new filter for input object.
 * @function buildFilter
 * @param {Object} input input object schema
 * @param {Array} schema
 * @returns {Object}
 */
export const buildFilter = (input, schema) => {
  let newFilter = []
  for (let [key, value] of Object.entries(input)) {
    if (value || value === false) {
      const field = key.replace(/(Value|Operator)$/, '');
      const column = schema.find(item => item.field === field || (item.filter === 'container' && (item.field + '.' + item.textField) === field));

      if (column.filter === 'date' && value instanceof Date) {
        value.setHours(0, 0, 0, 0);
      }

      let oldItem = newFilter.find(item => item.field === field);
      if (oldItem) {
        oldItem.field + 'Operator' === key ? oldItem.operator = value : oldItem.value = value
        newFilter = [...newFilter]
      }
      else {
        let newFilterItem
        if (key.search("Value") !== -1) {
          newFilterItem = {
            field: field,
            operator: column.filter && [...dateTypes, 'numeric', 'unitnumeric', 'boolean', 'enum', 'buttongroup', 'radio'].includes(column.filter) ? 'eq' : 'contains',
            value: value,
            typeId: column.filter === 'datetime' ? 13 : null
          }
        }
        else {
          newFilterItem = {
            field: field,
            operator: value,
            value: "",
            typeId: column.filter === 'datetime' ? 13 : null
          }
        }
        newFilter = [...newFilter, newFilterItem]
      }
    }
  }
  newFilter = {
    logic: "and",
    filters: [
      ...newFilter,
    ]
  }
  return newFilter;
}

/**
 * Returns boolean that determines whether the email is valid. 
 * @function validEmail
 * @param {string} email string input which is going to be validated
 * @returns {boolean}
 */
export const validEmail = (email) => { return /\S+@\S+\.\S+/.test(email); }

/**
 * Adds target route to route history.
 * @function onMenuSelect
 * @param {string} targetRoute target of route
 * @param {string} currLocation current location
 * @param {Array} routeHistory history of route
 */
export const onMenuSelect = (targetRoute, currLocation, routeHistory) => {
  if (targetRoute === undefined || currLocation === undefined)
    return;

  if (currLocation !== targetRoute)
    routeHistory.push(targetRoute);
}

/**
 * Returns object of url params from string. 
 * @function getURLParams
 * @param {Array} names array of names of params.
 * @returns {Object}
 */
export const getURLParamsByNames = (names) => {
  const urlParamsPart = window.location.href.split('?')[1];
  if (urlParamsPart === undefined)
    return {};

  const paramsWithValues = urlParamsPart.split('&');
  let URLParams = {};

  names.forEach(name => {
    const paramWithValue = paramsWithValues.find(p => p.includes(name));
    if (paramWithValue)
      URLParams[name] = paramWithValue.split('=')[1];
  });
  return URLParams;
}

/**
* After Login handle
* @function getURLParams
* @param {string} username user name to log in.
* @returns {Object}
*/
export const afterLoginHandle = (props, username) => {
  let {
    rememberDisabled,
    afterLoginlocation,
    location,
    navigate,
    history,
    user
  } = props;

  if (!rememberDisabled) {
    if (username) {
      localStorage.setItem('username', username);
    }
    else {
      localStorage.removeItem('username');
    }
  }

  afterLoginlocation = afterLoginlocation ? afterLoginlocation : user.Route ? { from: { pathname: user.Route } } : { from: { pathname: '/' } };
  if (location && location.state && location.state.from) {
    const { pathname } = location.state.from;
    if (pathname !== "/" && !pathname.includes("/prihlasenie") && !pathname.includes("/stranka_nenajdena"))
      afterLoginlocation = location.state;
  }

  const { from } = afterLoginlocation;
  navigate ? navigate(from) : history.replace(from);//navigate - react router v6; history - react router v5
}

/**
 * Returns new items without textfield.
 * @function removeTextField
 * @param {Array} items
 * @param {Array} schema
 * @returns {Object}
 */
export const removeTextField = (items, schema) => {
  items.map(el => {
    const col = schema.find(item => item.field + "." + item.textField === el.field)
    if (col) {
      el.field = col.field;
    }
    return el;
  })
  return items;
}

/**
 * Returns new items with textfield.
 * @function removeTextField
 * @param {Array} items
 * @param {Array} schema
 * @returns {Object}
 */
export const addTextField = (items, schema) => {
  items.map(el => {
    const col = schema.find(item => item.field === el.field)
    if (col && col.filter === "container" && el.field !== col.field + "." + col.textField) {
      el.field = col.field + "." + col.textField;
    }
    return el;
  })
  return items;
}

/**
 * Returns field format (replace {0:} chars). 
 * @function fieldFormat
 * @param {string} columnFormat
 * @returns {string}
 */
export const fieldFormat = (columnFormat) => { return columnFormat ? columnFormat.replace(/^\{\d:|\}$/g, "") : null; }

/** 
* Return parsed default value
* @function parseValue
* @param {string} defaultValue
* @param {string} type
* @return {*} 
*/
export const parseValue = (value, type) => {
  if (_.isNil(value)) return null;

  const user = storage.getUser();
  switch (value) {
    case "CURRENTUSER":
      return user.I_UZ;
    case "CURRENT_OZ":
      return user.Uzivatel.C_OZ.I_OZ;
    case "YEAR":
      return (new Date()).getFullYear();
    case "PREV_YEAR":
      return (new Date()).getFullYear() - 1;
    case "MONTH":
      return (new Date()).getMonth() + 1;
    case "PREV_MONTH":
      const month = (new Date()).getMonth() + 1;
      return month === 1 ? 12 : month - 1;
    // no default
  }

  switch (type) {
    case "dateinput":
    case "date":
    case "datetime":
    case "datetimeinput":
    case "time":
    case "timeinput":
      if (value.includes("SYSDATE")) {
        let newDate = new Date();
        if (value !== "SYSDATE") {
          newDate.setDate(newDate.getDate() + Number(value.replace('SYSDATE', '')));
        }
        return newDate;
      }
      else {
        return strToDate(value);
      }
    case "boolean":
      // eslint-disable-next-line
      return Boolean(eval(value));
    case "string":
    case "none":
      return value;
    default:
      try {
        // eslint-disable-next-line
        return eval(value);
      } catch (error) {
        return value;
      }
  }
}

/**
 * Get value by path (except null items).
 * @function getValueByPath
 * @param {Object} obj object
 * @param {String} path path
 */
export const getValueByPath = (obj, path) => {
  if (_.isNil(obj)) return null;
  if (_.isArray(obj)) {
    return obj.map(item => getValueByPath(item, path)).filter(item => !_.isNil(item)).flat();
  }
  const paths = path.split('.');
  const item = _.get(obj, paths[0]);
  if (paths.length === 1) {
    return item;
  }
  const subPath = paths.slice(1).join('.');
  return getValueByPath(item, subPath);
}

/**
 * Get primary field without copy/picker part.
 * @function getPrimaryField
 * @param {String} primaryField primaryField
 */
export const getPrimaryField = (primaryField) => {
  return primaryField?.split('-').pop();
}

/**
 * Get decimals count 
 * @function getDecimalsCount
 * @param {decimal} value value
 */
export const getDecimalsCount = (value) => {
  if (!value || Math.floor(value) === value) return 0;
  return value.toString().split(".")[1].length || 0;
}

/**
 * Process data before UID request
 * @function processBefore
 * @param {array} schema schema
 * @param {array} d data
 */
export const processBefore = (schema, d) => {
  const dateColumns = schema.filter(x => isDateType(x.type));
  const enumColumns = schema.filter(x => x.type === 'enum' || x.type === 'buttongroup' || x.type === 'radio');
  const data = { ...d };
  dateColumns.forEach(x => {
    _.set(data, x.field, addOffset(_.get(data, x.field)));
  });
  enumColumns.forEach(x => {
    if (x.primaryField === "I_HOD") {
      data[x.field] = _.isNil(data[x.field]) ? null : data[x.field][x.primaryField];
    }
  });
  schema.forEach(x => {
    if ((x.type === "numeric" || x.type === "unitnumeric") && getDecimalsCount(data[x.field]) > 3) {
      data[x.field] = data[x.field].toString();
    }
    if (x.type === "container") {
      x.schema.forEach(column => {
        if (column.type === "enum" || column.type === "buttongroup" || column.type === "radio") {
          if (data[x.field] && data[x.field][column.field] && (typeof data[x.field][column.field] === 'object')) {
            const val = data[x.field][column.field][column.primaryField];
            data[x.field][column.field] = _.isNil(val) ? null : val;
          }
        }
      })
    }
    if (x.type === "daterange" || x.type === "21") {
      let dateRangeObj = _.get(data, x.field);
      if (dateRangeObj && dateRangeObj.start && dateRangeObj.end) {
        dateRangeObj.start = addOffset(dateRangeObj.start);
        dateRangeObj.end = addOffset(dateRangeObj.end);
        _.set(data, x.field, dateRangeObj);
      }
    }
  })
  return data;
}

/**
 * Process data after GET request
 * DateTime from server should not end with Z (wrong UTC).
 * @function processAfter
 * @param {array} schema schema
 * @param {array} d data
 */
export const processAfter = (schema, data) => {
  if (!schema || schema.length === 0) { return data; }
  const dateColumns = schema.filter(x => isDateType(x.type));
  dateColumns.forEach(column => {
    const value = _.get(data, column.field);
    _.set(data, column.field, strToDate(!value || value instanceof Date ? value : value.replace(/Z$/, "")));
  })
  const dateRangeColumns = schema.filter(x => x.type === "daterange" || x.type === "21");
  dateRangeColumns.forEach(column => {
    const value = _.get(data, column.field);
    if (value && value.start && value.end) {
      value.start = strToDate(!value.start || value.start instanceof Date ? value.start : value.start.replace(/Z$/, ""))
      value.end = strToDate(!value.end || value.end instanceof Date ? value.end : value.end.replace(/Z$/, ""))
      _.set(data, column.field, value);
    }
  })
  return data;
}
/**
 * Process data after GET request
 * Emus converts to objects.
 * @function processAfter
 * @param {array} schema schema
 * @param {object} data data
 */
export const processEnumsAfter = (schema, data) => {
  const enumColumns = schema?.filter(x => x.type === 'enum' || x.type === 'buttongroup' || x.type === 'radio');
  enumColumns.forEach(column => {
    if (typeof (data[column.field]) !== 'object') {
      const value = column.EnumData?.find(el => el[column.primaryField] === _.get(data, column.field) || el[column.primaryField] === _.get(data, column.field + "." + column.primaryField)) || null
      _.set(data, column.field, value);
    }
  })
  return data;
}

/**
 * Is value invalid
 * @function isInvalid
 * @param {Object} field field
 * @param {Object} value value
 * @param {Boolean} inEdit inEdit
 * @param {Object} data  form data
 */
export const isInvalid = (field, value, inEdit, data = null) => {
  if (field.disabled || field.hidden) {
    return false;
  }

  return ((((value instanceof Object && !(value instanceof Date) && !(Array.isArray(value)) && field.primaryField && _.isNil(_.get(value, getPrimaryField(field.primaryField)))) || (!(value || value === 0 || value === false)) || (/^\s+$/gm.test(value))) && field.required)
    || (value !== null && value !== undefined && ["numeric", "unitnumeric"].includes(field.type) && ((field.min && (value < field.min)) || ((field.max && (value > field.max)))))
    || (value && field.type === "string" && ((field.minLength && (value.length < field.minLength)) || ((field.maxLength && (value.length > field.maxLength)))))
    || (value && field.type === "date" && ((field.min && (value.getTime() < field.min.getTime())) || (field.max && (value.getTime() > field.max.getTime()))))
    || (value && field.type === "password" && ((field.minLength && (value.length < field.minLength)) || ((field.maxLength && (value.length > field.maxLength))) || (!new RegExp(window.config.passwordPattern).test(value))))
    || (field.type === "password" && !inEdit && !value)
    || (value && field.type === "email" && !new RegExp(/^\S+@\S+\.\S+$/).test(value))
    || (value && field.pattern && !new RegExp(field.pattern).test(value))
    || (value && (field.type === "multipicker" || field.type === "multi") && (Array.isArray(value) && value.length === 0))
    || (field.isInvalid && field.isInvalid(field, value, data))
  );
}

/**
 * Text for discard/save changes dialog.
 * @function buildSaveDiscardText
 * @param {Object} id id
 * @param {Boolean} saveChanges saveChanges
 * @param {string} saveChangesDialogText saveChangesDialogText
 * @param {string} discardText discardText
 */
export const buildSaveDiscardText = (id, saveChanges, saveChangesDialogText, discardText) => {
  const akcia = saveChanges ? "uložiť" : "zahodiť";
  const text = saveChanges ? saveChangesDialogText : discardText;

  return text ? text
    : id
      ? `Máte rozpracovaný záznam ${id}. Chcete ${akcia} zmeny?`
      : `Máte rozpracovaný nový záznam. Chcete ${akcia} zmeny?`;
};

/**
 * Add filter to history.
 * @function addHistoryFilter
 * @param {Object} value value
 * @param {string} primaryField primaryField
 * @param {Boolean} isFast isFast
 */
export const addHistoryFilter = (value, primaryField) => {
  if (_.isEmpty(value.filters)) { return; }

  let allFilters = JSON.parse(localStorage.getItem("historyFilterList")) || {};
  const filters = value.filters;
  const oldFilter = (allFilters?.[primaryField] || []).filter(x =>
    _.xorWith(filters, x, (a, b) => a.join === undefined && b.join === undefined ? a.field === b.field && a.operator === b.operator : _.isEqual(a, b)).length !== 0
  );
  const newFilter = [filters, ...oldFilter];
  allFilters[primaryField] = newFilter.slice(0, 20);
  localStorage.setItem("historyFilterList", JSON.stringify(allFilters));
}

/**
 * Get filter from history.
 * @function getHistoryFilter
 * @param {string} primaryField primaryField
 */
export const getHistoryFilter = (primaryField) => {
  const allFilters = JSON.parse(localStorage.getItem("historyFilterList")) || {};
  return allFilters[primaryField] || [];
}

/**
 * Returns filters array wihtout form fields.
 * @function removeFormFields
 * @param {array} filters filters
 */
export const removeFormFields = (filters) => {
  return _.map(filters, item => _.pick(item, ['field', 'operator', 'value', 'left', 'right', 'join']));
}

/**
 * Detect active user filter.
 * @function isUserFilterActive
 * @param {array} filters filters
 * @param {object} userFilter userFilter
 */
export const isUserFilterActive = (filters, userFilter) => {
  const userFilters = removeFormFields(userFilter.filters);
  return _.xorWith(userFilters, _.intersectionWith(removeFormFields(filters), userFilters, _.isEqual), _.isEqual).length === 0;
}


/**
 * Set/remove fixed filter for field and operator
 * @param {object} s 
 * @param {string|arrray} field
 * @param {object} filter
 * @param {boolean} remove
 */
export const setFixedFilter = (s, field, filter, remove) => {
  const fields = _.isArray(field) ? field : [field];
  s.detailSchema = s.detailSchema.map(x => {
    const fixedFilters = x.fixedFilter ? x.fixedFilter.filters.filter(x => !(x.field === filter.field && (x.operator === filter.operator || !filter.operator))) : [];
    const filters = remove ? [] : [{ ...filter }];
    return fields.includes(x.field) ? { ...x, fixedFilter: { filters: [...fixedFilters, ...filters] } } : x;
  });
}

/**
 * Set disabled for field
 * @param {object} s
 * @param {string|arrray} field
 * @param {boolean|function} isDisabled
 */
export const setDisabled = (s, field, isDisabled) => {
  const primaryField = s.primaryField;
  const dataItem = s[primaryField].detailData;
  const fields = _.isArray(field) ? field : [field];
  s.detailSchema = s.detailSchema.map(x => {
    const disabled = _.isFunction(isDisabled) ? isDisabled(x, dataItem) : isDisabled;
    return fields.includes(x.field) ? { ...x, disabled: disabled, editable: !disabled } : x
  });
}

/**
 * Set hidden for field
 * @param {object} s
 * @param {string|arrray} field 
 * @param {boolean|function} isHidden
 */
export const setHidden = (s, field, isHidden) => {
  const primaryField = s.primaryField;
  const dataItem = s[primaryField].detailData;
  const fields = _.isArray(field) ? field : [field];
  s.detailSchema = s.detailSchema.map(x => {
    const hidden = _.isFunction(isHidden) ? isHidden(x, dataItem) : isHidden;
    return fields.includes(x.field) ? { ...x, disabled: hidden, editable: !hidden, className: hidden ? "hidden" : null } : x
  });
}

/**
 * Set required for field
 * @param {object} s 
 * @param {string|arrray} field 
 * @param {boolean|function} isRequired
 */
export const setRequired = (s, field, isRequired) => {
  const primaryField = s.primaryField;
  const dataItem = s[primaryField].detailData;
  const fields = _.isArray(field) ? field : [field];
  s.detailSchema = s.detailSchema.map(x => {
    const required = _.isFunction(isRequired) ? isRequired(x, dataItem) : isRequired;
    return fields.includes(x.field) ? { ...x, required: required } : x
  });
}

/**
 * Set field value in detailData
 * @param {object} s 
 * @param {string|arrray} field 
 * @param {object} value
 */
export const setValue = (s, field, value) => {
  const primaryField = s.primaryField;
  const fields = _.isArray(field) ? field : [field];
  fields.forEach(x => { _.set(s[primaryField].detailData, x, value); });
}

/**
 * Get field value from detailData
 * @param {object} s 
 * @param {string} field 
 * @returns {object} 
 */
export const getValue = (s, field) => {
  const primaryField = s.primaryField;
  return _.get(s[primaryField].detailData, field);
}

/**
 * Get path from menu tree.
 * @function getPath
 * @param {string} route route
 * @param {array} menu menu
 */
export const getPath = (route, menu) => {
  // split("#")[0] - odstránenie parametrov pre dashboard
  if (menu.Route?.split("#")[0] === route) {
    return [menu];
  } else {
    if (menu.children)
      for (let child of menu.children) {
        let tmp = getPath(route, child);
        if (tmp.length) {
          return [menu, ...tmp];
        }
      }
    return [];
  }
}

/**
 * Get path from menu tree by id.
 * @function getPath
 * @param {number} id Menu id
 * @param {array} menu menu
 */
export const getPathById = (id, menu) => {
  if (menu.MenuID === id) {
    return [menu];
  } else {
    if (menu.children)
      for (let child of menu.children) {
        let tmp = getPathById(id, child);
        if (tmp.length) {
          return [menu, ...tmp];
        }
      }
    return [];
  }
}

/**
 * Funkcia vráti či rodné číslo je valídne
 * @function isBICValid
 * @param {string} rc Slovenské rodné číslo  
 * @returns {Boolean} 
 */
export const isBICValid = (rc) => {
  if (stringIsNullOrEmpty(rc)) {
    return false;
  } else {
    if (rc.indexOf('/') !== -1) {
      rc.replace('/', '');
    }
    if (rc.length < 9 || parseInt(rc) % 11 !== 0) {
      return false;
    } else return true;
  }
}

/**
 * Funkcia vráti dátum narodenia a pohlavie z rodného čisla
 * @function getBirthDateFromBIC
 * @param {string} rc Slovenské rodné číslo  
 * @returns {Object} {DateOfBitrh, Sex}
 */
export const getBirthDateFromBIC = (rc) => {
  if (isBICValid(rc)) {
    if (rc.indexOf('/') !== -1) {
      rc.replace('/', '');
    }
    let rok, mesiac, den, pohlavie;
    let parts = rc.substring(0, 6).match(/.{2}/g).map(x => parseInt(x));
    //vypocet roku
    if (rc.length === 9) {
      rok = 1900 + parts[0];
    } else if (parts[0] > 54) {
      rok = 1900 + parts[0];
    } else {
      rok = 2000 + parts[0];
    }
    //vypocet mesiaca a pohlavia
    if (parts[1] > 50) {
      mesiac = parts[1] - 51;
      pohlavie = "Ž";
    } else {
      mesiac = parts[1] - 1;
      pohlavie = "M";
    }
    //vypoced datumu
    den = parts[2];
    let date = new Date(rok, mesiac, den);
    return { DateOfBitrh: date, Sex: pohlavie };
  }
  else return null;
}