import actionTypes from './ActionTypes';
import * as dogUtils from '../utils/DogUtils';

const getDogsSuccess = (res) => ({
  type: actionTypes.GET_DOGS_SUCCESS,
  res
});

const getDogsRequest = () => ({
  type: actionTypes.GET_DOGS_REQUEST
});

const getDogsFailure = (error) => ({
  type: actionTypes.GET_DOGS_FAILURE,
  error
});

export const getDogs = (form) => {
  return async (dispatch) => {
    try {
      dispatch(getDogsRequest());
      let res = await dogUtils.getDogs(form);
      dispatch(getDogsSuccess(res));
    } catch(error) {
      dispatch(getDogsFailure(error))
      console.error(error);
    }
  }
}