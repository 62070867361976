import React from 'react';
import List from '../../coraWebMComponents/dataDisplay/List';
import ListItem from '../../coraWebMComponents/dataDisplay/ListItem';
import Paper from '../../coraWebMComponents/surfaces/Paper';
import AppBar from '../appBar/AppBar';
import RaisedButton from '../../coraWebMComponents/inputs/RaisedButton';
import Info from '../../coraWebMComponents/feedBack/Info';
import LinearProgress from '../../coraWebMComponents/feedBack/progress/LinearProgress';
import * as convert from '../../coraWebMComponents/utils/convert';
import * as msgActions from '../../actions/MsgActions';
import routes from '../../routes';
import { connect } from 'react-redux';
import withRouter from '../../coraWebMComponents/utils/withRouter';

class MsgUnRead extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false
    };
  }

  handleClick = async (iUzMsg) => {
    this.setState({ loading: true });
    await this.props.dispatch(msgActions.setReaded(iUzMsg));
    await this.props.dispatch(msgActions.getMsgUnRead(this.props.user.IdUz));
    this.setState({ loading: false });

    if (this.props.msgUnRead.length === 0) {
      this.props.navigate(routes.HOME);
    }
  }

  componentDidMount = async () => {
    await this.props.dispatch(msgActions.getMsgUnRead(this.props.user.IdUz));

    if (this.props.msgUnRead.length === 0)
      this.props.navigate(routes.HOME);
  }

  render() {
    return (
      <div>
        <AppBar title='Neprečitané správy' closable={false} />
        <LinearProgress loading={this.props.isLoading || this.state.loading} />
        <div className='container'>
          {(this.props.msgUnRead.length === 0 && !this.props.isLoading) &&
            <div>
              <Paper className='content' elevation={3} >
                <React.Fragment>
                  <Info text='Žiadne nové správy.' />
                </React.Fragment>
              </Paper>
            </div>
          }
          <List>
            {this.props.msgUnRead.map((msg) =>
              <Paper className='content' elevation={3} key={msg.I_UZ_MSG.toString()}>
                <React.Fragment>
                  <Item key={msg.I_UZ_MSG.toString()} data={msg} />
                  <RaisedButton fullwidth={true} onClick={event => this.handleClick(msg.I_UZ_MSG)}>
                    {'Označiť správu ako prečitanú'}
                  </RaisedButton>
                </React.Fragment>
              </Paper>
            )}
          </List>
        </div>
      </div>
    );
  }
}

class Item extends React.Component {
  render() {
    return (
      <ListItem>
        <div className="item" style={{ overflow: 'overlay' }}>
          <div> <b>Názov: </b>{this.props.data.MSG_HL}</div>
          <div> <b>Platnosť od: </b>{convert.convertDate(this.props.data.D_VYTV)}</div>
          <div> <b>Platnosť do: </b>{convert.convertDate(this.props.data.D_PLAT_DO)}</div>
          <div> <b>Správa: </b>{this.props.data.MSG_TXT}</div>
        </div>
      </ListItem>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.data,
    isLoading: state.msgUnRead.isLoading,
    msgUnRead: state.msgUnRead.data
  }
}

export default withRouter(connect(mapStateToProps)(MsgUnRead));