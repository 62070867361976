import actionTypes from './ActionTypes';
import * as evoUtils from '../utils/EvoUtils';
import * as tresspassUtils from '../utils/TresspassUtils';

const getEvoSuccess = (res) => ({
  type: actionTypes.GET_EVO_SUCCESS,
  res
});

const getEvoRequest = () => ({
  type: actionTypes.GET_EVO_REQUEST
});

const getEvoFailure = (error) => ({
  type: actionTypes.GET_EVO_FAILURE,
  error
});

export const getEvo = (form) => {
  return async (dispatch) => {
    try {
      dispatch(getEvoRequest());
      let res = await evoUtils.getEvo(form);
      dispatch(getEvoSuccess(res));
    } catch(error) {
      dispatch(getEvoFailure(error))
      console.error(error);
    }
  }
}

const getCityOfSetupRequest = () => ({
  type: actionTypes.GET_CITY_OF_SETUP_REQUEST
});

const getCityOfSetupSuccess = (res) => ({
  type: actionTypes.GET_CITY_OF_SETUP_SUCCESS,
  res
});

const getCityOfSetupFailure = (error) => ({
  type: actionTypes.GET_CITY_OF_SETUP_FAILURE,
  error
});

export const getCityOfSetup = () => {
  return async (dispatch) => {
    try {
      dispatch(getCityOfSetupRequest());
      let res = await tresspassUtils.getPredDef(999998015);
      dispatch(getCityOfSetupSuccess(res));
    } catch (error) {
      dispatch(getCityOfSetupFailure(error));
    }
  }
}

const getAdressOfSetupRequest = () => ({
  type: actionTypes.GET_ADRESS_OF_SETUP_REQUEST
});

const getAdressOfSetupSuccess = (res) => ({
  type: actionTypes.GET_ADRESS_OF_SETUP_SUCCESS,
  res
});

const getAdressOfSetupFailure = (error) => ({
  type: actionTypes.GET_ADRESS_OF_SETUP_FAILURE,
  error
});

export const getAdressOfSetup = () => {
  return async (dispatch) => {
    try {
      dispatch(getAdressOfSetupRequest());
      let res = await tresspassUtils.getPredDef(999998027);
      dispatch(getAdressOfSetupSuccess(res));
    } catch (error) {
      dispatch(getAdressOfSetupFailure(error));
    }
  }
}