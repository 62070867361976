import actionTypes from './ActionTypes';
import * as mapMarkerUtils from '../utils/MapMarker';
import * as userActions from './UserActions';

const getMapMarkersSuccess = (res) => ({
  type: actionTypes.GET_MAP_MARKER_SUCCESS,
  res
});

const getMapMarkersRequest = () => ({
  type: actionTypes.GET_MAP_MARKER_REQUEST
});

const getMapMarkersFailure = (error) => ({
  type: actionTypes.GET_MAP_MARKER_FAILURE,
  error
});

export const getMapMarkers = (allocation) => {
  return async (dispatch) => {
    try {
      dispatch(getMapMarkersRequest());
      let res = await mapMarkerUtils.getMapMarkers(allocation);
      dispatch(getMapMarkersSuccess(res));
    } catch (error) {
      dispatch(userActions.showMsg('Chyba pri ziskavaní hlasení.'));
      dispatch(getMapMarkersFailure(error))
      console.error(error);
    }
  }
}


const setMapMarkerRequest = () => ({
  type: actionTypes.SET_MAP_MARKER_REQUEST
});

const setMapMarkerSuccess = (res) => ({
  type: actionTypes.SET_MAP_MARKER_SUCCESS,
  res
});

const setMapMarkerFailure = (error) => ({
  type: actionTypes.SET_MAP_MARKER_FAILURE,
  error
});

export const setMapMarker = (iHlasPr, iState) => {
  return async (dispatch) => {
    try {
      dispatch(setMapMarkerRequest());
      let res = await mapMarkerUtils.setMapMarker(iHlasPr, iState);
      dispatch(setMapMarkerSuccess(res));
      if (iState === 3)
        dispatch(userActions.showMsg('Stav hlásenia - vyriešené.'));
      else
        dispatch(userActions.showMsg('Vozidlo nenajdené.'));

      return true;
    } catch (error) {
      dispatch(userActions.showMsg('Chyba pri nastavení stavu hlasenia.'));
      dispatch(setMapMarkerFailure(error));
      return false;
    }
  }
}

const getMapFotosSuccess = (res) => ({
  type: actionTypes.GET_MAP_FOTO_SUCCESS,
  res
});

const getMapFotosRequest = () => ({
  type: actionTypes.GET_MAP_FOTO_REQUEST
});

const getMapFotosFailure = (error) => ({
  type: actionTypes.GET_MAP_FOTO_FAILURE,
  error
});

export const getMapFotos = (iHlasPr) => {
  return async (dispatch) => {
    try {
      dispatch(getMapFotosRequest());
      let res = await mapMarkerUtils.getMapFotos(iHlasPr);
      dispatch(getMapFotosSuccess(res));
    } catch (error) {
      dispatch(userActions.showMsg('Chyba pri ziskavaní fotografií.'));
      dispatch(getMapFotosFailure(error))
      console.error(error);
    }
  }
}
