import actionTypes from './ActionTypes';
import * as streetUtils from '../utils/StreetUtils';
import * as userActions from './UserActions';

const getStreetsSuccess = (res) => ({
  type: actionTypes.GET_STREETS_SUCCESS,
  res
});

const getStreetsRequest = () => ({
  type: actionTypes.GET_STREETS_REQUEST
});

const getStreetsFailure = (error) => ({
  type: actionTypes.GET_STREETS_FAILURE,
  error
});

export const getStreets = (text) => {
  return async (dispatch) => {
    try {
      dispatch(getStreetsRequest());
      let res = await streetUtils.getStreets(text);
      dispatch(getStreetsSuccess(res));
    } catch(error) {
      dispatch(userActions.showMsg('Neočakávaná chyba.'));
      dispatch(getStreetsFailure(error))
      console.error(error);
    }
  }
}