import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import classNames from 'classnames';

const styles = theme => ({
  button: {
    padding: '7px',
  },
  disableHover: {
    background: 'transparent!important'
  }
});

class IconButtonComponent extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Tooltip
        title={this.props.toolTip}
        placement={this.props.toolTipPlace}
        disableFocusListener={this.props.disableTooltip ? true : false}
        disableTouchListener={this.props.disableTooltip ? true : false}
        disableHoverListener={this.props.disableTooltip ? true : false}
      >
        <span>
          <IconButton
            className={classNames(classes.button, this.props.className, this.props.disableHover && classes.disableHover)}
            color={this.props.color}
            label={this.props.label}
            disabled={this.props.disabled}
            onClick={this.props.onClick}
            style={this.props.style}
            disableRipple={this.props.disableRipple ? this.props.disableRipple : false}
          >
            {this.props.children}
          </IconButton >
        </span>
      </Tooltip>
    )
  }
}

IconButtonComponent.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  onClick: PropTypes.func,
  toolTip: PropTypes.string,
  toolTipPlace: PropTypes.string,
  disableRipple: PropTypes.bool,
  disableHover: PropTypes.bool,
  disableTooltip: PropTypes.bool,
}

export default withStyles(styles)(IconButtonComponent);