import React from 'react';
import { connect } from 'react-redux';
import TextField from '../../coraWebMComponents/inputs/TextField';
import DatePicker from '../../coraWebMComponents/inputs/DatePicker';
import Paper from '../../coraWebMComponents/surfaces/Paper';
import RaisedButton from '../../coraWebMComponents/inputs/RaisedButton';
import AppBar from '../appBar/AppBarDrawer';
import Error from '../../coraWebMComponents/feedBack/Error';
import CircularProgress from '../../coraWebMComponents/feedBack/progress/CircularProgress';
import routes from '../../routes';
import * as validator from '../../coraWebMComponents/utils/validator';
import * as trespassActions from '../../actions/TrespassActions';
import * as personActions from '../../actions/PersonAction';
import Autocomplete from '../../coraWebMComponents/inputs/Autocomplete';
import withRouter from '../../coraWebMComponents/utils/withRouter';

class TrespassPersonSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: true,
      rc: '',
      name: '',
      surname: '',
      birthDate: null,
      errorText: '',
      errorTextRc: '',
      L_ACCESS_PERSON: false,
      person: { I_O: 0, OSOBA: '' }
    };
  }

  componentDidMount = async () => {
    await this.props.dispatch(trespassActions.getAccessPerson());
    this.setState({ isLoad: false, L_ACCESS_PERSON: parseInt(this.props.phAccessPerson.HODN), });
  }

  handleChange = (name, value) => {
    this.setState({ [name]: value });
  }

  handleChangePersonList = (searchText) => {
    searchText = searchText.trim();
    const errorTextRc = validator.isValidRc(searchText) ? '' : 'RČ môže obsahovať iba číslice (max. 10).';
    this.setState({ errorTextRc });
    this.props.dispatch(personActions.getPersonList(searchText, window.config.autocompleteCountCharStart_person || 6));
  }

  handleSelectPerson = async (name, value) => {

    if (value) {
      if (value.I_O > 0) {
        await this.props.dispatch(personActions.getPerson(value.I_O));
        this.setState({
          person: { I_O: value.I_O, OSOBA: this.props.person.ICO_RC },
          rc: this.props.person.ICO_RC.trim(),
          name: this.props.person.MENO ? this.props.person.MENO : '',
          surname: this.props.person.PF,
          birthDate: this.props.person.D_NAR ? new Date(this.props.person.D_NAR) : null
        });
      }
      else
        this.setState({ person: value, rc: value.OSOBA, name: '', surname: '', birthDate: null });
    }
    else
      this.setState({ person: { I_O: 0, OSOBA: '' }, rc: '', name: '', surname: '', birthDate: null });

  }

  validateForm = () => {
    const rc = this.state.rc.trim();
    const name = this.state.name;
    const surname = this.state.surname;
    const birthDate = this.state.birthDate;

    if (validator.isValidRc(rc) || (surname && name) || (surname && birthDate instanceof Date)) {
      this.setState({ errorText: '' });
      return true;
    }
    else {
      this.setState({ errorText: 'Je nutné zadať RČ osoby bez lomítka, meno s priezviskom alebo priezvisko s dátumom narodenia (DD.MM.YYYY).' });
      return false;
    }
  }

  handleSearchPerson = () => {
    let isValid = this.validateForm();

    if (isValid) {
      this.props.navigate(routes.PERSON, { state: this.state });
    }
  }

  handleSearchTrespasses = () => {
    let isValid = this.validateForm();

    if (isValid) {
      this.props.navigate(routes.TRESPASS_PERSON, { state: this.state });
    }
  }

  render() {
    return (
      <div>
        <AppBar title='Osoba' />
        <div className='container'>
          <Paper className='content' elevation={1} >
            <React.Fragment>
              <h3>Kontrola osoby</h3>
              {this.state.L_ACCESS_PERSON === 0 &&
                <TextField
                  fullwidth={true}
                  label="RČ/IČO"
                  onChange={this.handleChange}
                  value={this.state.rc}
                  name='rc'
                />}
              {this.state.L_ACCESS_PERSON === 1 &&
                <Autocomplete
                  data={this.props.personList}
                  total={this.props.totalPerson}
                  isLoading={this.props.isLoadingPersonList}
                  schema={{ label: 'OSOBA', value: 'I_O' }}
                  onChange={this.handleChangePersonList}
                  onSelect={this.handleSelectPerson}
                  selectedItem={this.state.person}
                  label="RČ/IČO"
                  errorText={this.state.errorTextRc}
                  name='person'
                  countCharStart={window.config.autocompleteCountCharStart_person || 6}
                />}
              <div className="row">
                <TextField
                  style={{ width: '43%' }}
                  label="Meno"
                  onChange={this.handleChange}
                  value={this.state.name}
                  name='name'
                />
                <TextField
                  style={{ width: '55%' }}
                  label="Priezvisko/Názov"
                  onChange={this.handleChange}
                  value={this.state.surname}
                  name='surname'
                />
              </div>
              <DatePicker
                label="Dát. nar. os."
                value={this.state.birthDate}
                onChange={this.handleChange}
                clearable={true}
                name='birthDate'
                keyboards
                disableFuture={true}
                disablePast={false}
                fullWidth={true}
              />
              <RaisedButton
                fullwidth={true}
                onClick={this.handleSearchTrespasses}
              >
                {'Vyhľadať osobu v priestupkoch'}
              </RaisedButton>
              <RaisedButton
                fullwidth={true}
                onClick={this.handleSearchPerson}
              >
                {'Vyhľadať osobu v evidencii'}
              </RaisedButton>
              <Error text={this.state.errorText} />
              <CircularProgress loading={this.state.isLoad || this.props.isLoadingPerson} />
            </React.Fragment>
          </Paper>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    personList: state.personList.data,
    totalPerson: state.personList.total,
    isLoadingPersonList: state.personList.isLoading,
    person: state.person.data.Data,
    isLoadingPerson: state.person.isLoading,
    phAccessPerson: state.phAccessPerson.data,
  }
}

export default withRouter(connect(mapStateToProps)(TrespassPersonSearch));